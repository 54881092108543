type SVGProps = React.SVGProps<SVGSVGElement>

export const Logo = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 100" fill="currentColor" {...props}>
    <path d="M66.42,85.96c.33-.01.54.33.38.62l-2.67,4.62c-6.76,11.73-23.68,11.73-30.44,0L.18,33.11C.06,32.89,0,32.66,0,32.42c0-.47.25-.94.69-1.19,9.19-5.31,20.95-2.16,26.26,7.04l21.97,38.09c3.71,6.43,10.57,9.91,17.51,9.6Z" />
    <path d="M97.65,33.11l-25.22,43.73c-1.09,1.9-3.21,2.96-5.38,2.71-5.64-.64-10.93-3.77-14.14-8.93-.21-.34-.22-.78-.02-1.13l18.01-31.21c3.56-6.17,10.02-9.62,16.67-9.62,3.26,0,6.57.83,9.59,2.58.66.38.88,1.22.5,1.88Z" />
    <path d="M162.91,32.75h-12c-1.66,0-3,1.34-3,3v1.62c0,9.07.6,18.13,1.07,27.19.02.39.03.79.03,1.2,0,5.1-1.55,9.33-4.65,12.69-3.09,3.37-7.13,5.05-12.13,5.05-3.18,0-6.04-.77-8.58-2.32-2.55-1.55-4.53-3.64-5.93-6.28-1.41-2.63-2.11-5.73-2.11-9.27s.7-6.51,2.11-9.14c1.41-2.64,3.37-4.73,5.86-6.28,2.5-1.55,5.38-2.32,8.65-2.32,1.21,0,2.35.1,3.44.29,2.79.5,5.39-1.55,5.77-4.36l1.03-7.72c.1-.75-.28-1.49-.95-1.85h-.01c-3.73-1.92-7.9-2.88-12.54-2.88-6.08,0-11.52,1.51-16.28,4.5-4.78,3.01-8.52,7.1-11.25,12.28-2.73,5.19-4.09,11.05-4.09,17.6s1.36,12.28,4.09,17.46c2.73,5.19,6.47,9.28,11.25,12.28,4.77,3,10.15,4.5,16.14,4.5,4.73,0,8.97-.95,12.75-2.86,2.06-1.04,3.89-2.31,5.49-3.81.32-.3.85-.09.85.35v2.96c0,1.1.9,2,2,2h13c1.66,0,3-1.34,3-3v-59.89c0-1.66-1.34-3-3-3Z" />
    <path d="M236.23,32.75h-11.86c-1.65,0-3,1.34-3,3v34.79c0,4.09-1.04,7.28-3.13,9.55-2.1,2.27-5.01,3.41-8.73,3.41-2.45,0-4.59-.5-6.4-1.5-1.82-1-3.18-2.48-4.09-4.44-.91-1.95-1.37-4.29-1.37-7.02v-34.79c0-1.66-1.34-3-3-3h-11.99c-1.66,0-3,1.34-3,3v35.2c0,5.73,1.27,10.78,3.82,15.14,2.54,4.37,6.08,7.76,10.63,10.16,4.54,2.41,9.67,3.62,15.4,3.62s10.84-1.21,15.34-3.62c4.5-2.4,8.02-5.79,10.57-10.16,2.54-4.36,3.81-9.41,3.81-15.14v-35.2c0-1.66-1.34-3-3-3Z" />
    <path d="M256.41,0h11.86c1.65,0,3,1.34,3,3v92.64c0,1.66-1.34,3-3,3h-11.86c-1.65,0-3-1.34-3-3V3c0-1.66,1.34-3,3-3Z" />
    <path d="M327,32.75h-10.4c-1.1,0-2-.9-2-2V8.33c0-1.66-1.34-3-3-3h-11.86c-1.66,0-3,1.34-3,3v22.42c0,1.1-.89,2-2,2h-10.41c-1.66,0-3,1.34-3,3v10.69c0,1.1.89,2,2,2h44.66c1.1,0,2-.9,2-2v-10.69c0-1.66-1.34-3-3-3Z" />
    <path d="M312.41,53.31l-11.16,1.12c-2.55.26-4.5,2.41-4.5,4.98v36.24c0,1.66,1.34,3,3,3h11.86c1.66,0,3-1.34,3-3v-40.34c0-1.18-1.02-2.11-2.2-1.99Z" />
  </svg>
)
